import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
//import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

const IndexPage = ({ data, location }) => {
  const newsPosts = data.allContentfulMediaRoomNews.edges;
  const intl = useIntl();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          variableWidth: false,

          centerMode: false,
        },
      },
    ],
  };

  function handleChange(e) {
    console.log("Start sending event...")
    if (typeof window !== 'undefined'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "formSubmit" });
      console.log("Event sent!")
    }
  }

  return (
    <Layout location={location} locale={intl.locale} mode="bls">
      <Seo
        title={
          intl.locale === "en"
            ? `Cyberdyne set to become a world leader in Cybernics Technology`
            : `Cyberdyne etabliert sich als Marktführer der Cybernics Technologie`
        }
        description={
          intl.locale === "en"
            ? `Cyberdyne Care Robotics provides Cybernics technologies including cyborg, exoskeleton, robotics, assistive device, regenerative medicine, communication, and cleaning.`
            : `Cyberdyne Care Robotics bietet Cybernics Technologien inklusive Cyborg, Exoskletten, Robotern, Hilfsmitteln, Regenerationsmedizin, Kommunikation und Reinigung an. `
        }
        showSitename="false"
        //ogImage="/images/ogp/E169.jpg"
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/Gx3Sc0gyL2KygjlYoItex/6e87f29d8f2062cd9c58ba8cbb82fbfc/E169.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect sect_top__catch">
        <StaticImage
          src="../images/startseite-movie-poster.jpg"
          className="bg-absolute bg-top-catch"
          quality={55}
        />
        <div className="container mt-9 mt-sm-0">
          <div className="container__inner flex-row">
            {/*<div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "common.test" })}} />*/}
            <div className="flex-column-6 mt-9 mt-sm-0">
              {intl.locale === "en" ? (
                <h2 className="message-lg">
                  <strong>We develop</strong> technologies for humankind
                </h2>
              ) : (
                <h2 className="message-lg">
                  <strong>Wir entwickeln</strong> Technologien für Menschen
                </h2>
              )}
              <p className="message-sm txt txt-white">
                {intl.locale === "en"
                  ? `Cybernics technology was developed as human-assistive and
  enhancement technology and is expected to be used in a wide
  range of fields covering medicine, care, living, and
  labor-intensive jobs`
                  : `Die Cybernic-Technologie wurde als Hilfsmittel für den Menschen und Verbesserungstechnologie entwickelt. Sie soll in einer Vielzahl von Bereichen wie Medizin, Wohlfahrt, Katastrophenschutz und Unterhaltung eingesetzt werden.`}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*}
      <div className="sect bg-white p-4">
        <button
          type="submit"
          name="sendEvent"
          className="sendEvent"
          onClick={handleChange}
        >SEND EVENT</button>
      </div>
      */}
      <section className="sect bg-white p-0 position-relative">
        <StaticImage
          src="../images/products-start-teaser.png"
          className=" bg-absolute bg-sm-relative d-sm-none"
          quality={55}
        />
        <StaticImage
          src="../images/products-start-teaser-mobile@2x.png"
          className=" bg-absolute bg-sm-relative d-sm-block"
          quality={55}
        />
        <div className="sect_top__HAL___product">
          <div className="container">
            <div className="container__inner flex-row">
              {/*<div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "common.test" })}} />*/}
              <div className="flex-column-7"></div>
              <div className="flex-column-5">
                <p className="message-label-bold txt txt-rot">
                  {intl.locale === "en" ? `Products` : `Produkte`}
                </p>
                <h2 className="h3 font-weight-semibold">
                  {intl.locale === "en"
                    ? `Products for a better quality of life`
                    : `Produkte für mehr Lebensqualität`}
                </h2>
                <p className="message-sm">
                  {intl.locale === "en"
                    ? `Apply Cybernics technology to deliver revolutionary therapies
  and enhance quality of life for all people in need.`
                    : `Wenden Sie die Cybernics Technologie an, um revolutionäre Therapien durchzuführen und die Lebensqualität von hilfsbedürftigen Menschen  zu verbessern`}
                </p>
                <div>
                  <Link to="/products/" className="link-border">
                    {intl.locale === "en"
                      ? `Product Overview`
                      : `Produktübersicht`}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative">
        <StaticImage
          src="../images/treatment-big-teaser.png"
          className="sect_top__HAL___img1 d-sm-none"
          quality={55}
        />
        <StaticImage
          src="../images/treatment-big-teaser-mobile@2x.png"
          className="sect_top__HAL___img1 d-sm-block"
          quality={55}
        />

        <div className="floating-box left-bottom">
          <div className="bg-bls p-8">
            <p className="message-label-bold txt txt-rot">
              {intl.locale === "en" ? `HAL Treatment` : `HAL Therapie`}
            </p>
            <p className="message-md txt txt-white">
              {intl.locale === "en"
                ? `Back to a self-determined Life`
                : `Zurück in ein selbstbestimmtes Leben`}
            </p>
            <p className="message-sm txt txt-white">
              {intl.locale === "en"
                ? `Locomotor training performed using a neurologically controlled
  cyborg HAL enables the neural activity and repetitive excercises
  of specific tasks. HAL Therapy promotes learning and leads to the
  restructuring of appropriate proprioceptive feedback loop.`
                : `Lokomotionstraining mit dem neurologisch kontrollierten Cyborg HAL ermöglicht neuronale Aktivität und wiederholte Durchführung bestimmter Aufgaben. Die HAL-Therapie fördert das Lernen und führt zur Rekonstruktion einer angemessenen propriozeptiven Feedback-Schleife.`}
            </p>
            <div>
              <Link
                to="/treatment/cvd/"
                className="link-border link-border-white"
              >
                {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="sect_top__HAL___about bg-bge">
          <div className="container py-8 mb-8">
            <p className="message-label-bold">
              {intl.locale === "en"
                ? `Cyberdyne Care Robotics GmbH`
                : `Cyberdyne Care Robotics GmbH`}
            </p>
            <h2 className="message-lg">
              {intl.locale === "en"
                ? `We envision a future where humans and technology are seamlessly
  interfaced, feeding off of each other.`
                : `Wir stellen uns eine Zukunft vor, in der Menschen und Technologie nahtlos miteinander verbunden sind und sich gegenseitig unterstützen`}
            </h2>
            <div>
              <Link to="/company" className="link-border">
                {intl.locale === "en"
                  ? `Learn more about Cybernics`
                  : `Mehr über Cybernics erfahren`}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_Product__footer p-0">
        <div className="container py-1 my-1 flex-row">
          <div className="flex-column-6">
            <h2 className="h3">
              {intl.locale === "en"
                ? `See How Cyberdyne Can Change Your Life`
                : `Erkennen SIe wie Cyberdyne Ihr Leben verändern kannImproving Die Verbesserung`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `Your own life-quality begins with a quick call or email to Cyberdyne.`
                : `Ihrer Lebensqualität beginnt mit einem kurzen Anruf oder einer E-Mail an Cyberdyne.`}
            </p>
            <div style={{marginBottom: "5px"}}>
              <Link to="/contact/" className="link_button">
                {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="sect">
        <div className="container">
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en" ? `News` : `Neuigkeiten`}
          </p>
          <div className="slide slide-top">
            {/*JSON.stringify(newsPosts)*/}
            <Slider {...settings}>
              {newsPosts &&
                newsPosts.map(
                  ({ node: post }) =>
                    post.title && (
                      <div
                        className={`slide_link ${
                          !post.thumbnail && "slide_link__txtonly"
                        }`}
                      >
                        <div className="slide_link__img">
                          {post.thumbnail && (
                            <img
                              src={
                                post.thumbnail.file && post.thumbnail.file.url
                              }
                              className="thumbnail"
                              alt={
                                post.thumbnail.file &&
                                post.thumbnail.file.fileName
                              }
                            />
                          )}
                        </div>
                        <div
                          className={`slide_link__txt ${
                            !post.thumbnail && "slide_link__txtonly"
                          }`}
                        >
                          <p className="slide_link__txt___date">
                            {post.createdAt}
                          </p>
                          <p className="slide_link__txt___title">
                            {post.title}
                          </p>
                          <p className="excerpt">
                            {post.content &&
                              documentToPlainTextString(
                                JSON.parse(post.content.raw)
                              ).substr(0, 120)}
                          </p>
                          <Link
                            to={`/news/${post.slug}`}
                            className="slide_link__txt___more"
                          >
                            {intl.locale === "en" ? `Read` : `Lesen`}
                          </Link>
                        </div>
                      </div>
                    )
                )}
            </Slider>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default IndexPage;

export const query = graphql`
  query ($language: String) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulMediaRoomNews(
      limit: 5
      sort: { fields: createdAt, order: DESC }
      filter: { node_locale: { eq: $language }, slug: { ne: "do-not-delete-sample"} }
    ) {
      edges {
        node {
          slug
          content {
            raw
          }
          thumbnail {
            file {
              url
              fileName
            }
          }
          category
          updatedAt
          title
          createdAt(formatString: "DD. MMMM YYYY")
        }
      }
    }
    allFile(
      filter: { name: { ne: "index" }, sourceInstanceName: { eq: "pages" } }
    ) {
      edges {
        node {
          relativePath
          relativeDirectory
          name
        }
      }
    }
  }
`;
